/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function() {
				// JavaScript to be fired on all pages
			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
				var open = false;
				$("#toggle-wishlist").on('click', function() {
					open = !open;
					$(".wishlist").toggleClass('open', open);
				});

				// $(".staff-container").on('click', function() {
				// 	var target = $(this).data('target');
				//
				// 	$(".unfolded-content.open").removeClass('open');
				// 	$(target).addClass('open');
				// });

				// Swiper
				var swiper = new Swiper('.swiper-container', {
					// pagination: '.swiper-pagination',
					paginationClickable: true,
					nextButton: '.swiper-button-next',
					prevButton: '.swiper-button-prev',
					spaceBetween: 30
				});

				$('a[href*="#"]:not([href="#"])').click(function() {
					if($(this).data('scroll')) {
						if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
							var target = $(this.hash);
							target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
							if (target.length) {
								$('html, body').animate({
									scrollTop: target.offset().top - 100
								}, 1000);
								return false;
							}
						}
					}
				});

				// Custom selects
				$('select').each(function(){
					var $this = $(this), numberOfOptions = $(this).children('option').length;

					$this.addClass('select-hidden');
					$this.wrap('<div class="select"></div>');
					$this.after('<div class="select-styled"></div>');

					var $styledSelect = $this.next('div.select-styled');
					$styledSelect.text($this.children('option').eq(0).text());

					var $list = $('<ul />', {
						'class': 'select-options'
					}).insertAfter($styledSelect);

					for (var i = 0; i < numberOfOptions; i++) {
						$('<li />', {
							text: $this.children('option').eq(i).text(),
							rel: $this.children('option').eq(i).val()
						}).appendTo($list);
					}

					var $listItems = $list.children('li');

					$styledSelect.click(function(e) {
						e.stopPropagation();
						$('div.select-styled.active').each(function(){
							$(this).removeClass('active').next('ul.select-options').hide();
						});
						$(this).toggleClass('active').next('ul.select-options').toggle();
					});

					$listItems.click(function(e) {
						e.stopPropagation();
						$styledSelect.text($(this).text()).removeClass('active');
						$this.val($(this).attr('rel'));
						$list.hide();
					});

					$(document).click(function() {
						$styledSelect.removeClass('active');
						$list.hide();
					});
				});

				var iconOpen = 'fa fa-minus',
					iconClose = 'fa fa-plus';

				$(document).on('show.bs.collapse hide.bs.collapse', '.panel', function (e) {
					var $target = $(e.target)
					$target.siblings('.panel-heading')
						.find('em').toggleClass(iconOpen + ' ' + iconClose);
					if(e.type == 'show')
						$target.prev('.panel-heading').find('.panel-toggle').addClass('active');
					if(e.type == 'hide')
						$(this).find('.panel-toggle').not($target).removeClass('active');
				});

				$('body').on('shown.bs.collapse', function () {
					$(".navbar-toggle .glyphicon").removeClass("glyphicon-menu-hamburger").addClass("glyphicon-remove");
				});

				$('body').on('hidden.bs.collapse', function () {
					$(".navbar-toggle .glyphicon").removeClass("glyphicon-remove").addClass("glyphicon-menu-hamburger");
				});

				$(".prompt-leave").on('click', function(e) {
					e.preventDefault();
					var url = $(this).attr('href');

					bootbox.dialog({
						message: " ",
						buttons: {
							success: {
								label: "Lad os klare bookingen gratis",
								className: "btn-success btn-popup",
								callback: function() {
									$(".choose-center-single:not(.btn-chosen)").trigger('click');

									open = true;
									$(".wishlist").toggleClass('open', open);
								}
							},
							default: {
								label: "Gå videre til centret",
								className: "btn-default btn-popup",
								callback: function() {
									ga('send', {
										hitType: 'event',
										eventCategory: 'Outbound Link Gå Videre',
										eventAction: 'click',
										eventLabel: url
									});

									window.top.location.href = url;
								}
							}
						}
					});
				});


				$("#gform_page_2_2 .gform_page_footer").append('<div class="text-right" style="padding-top: 4px;">Når du har sendt denne besked, går vi straks i gang med at finde det helt rigtige sted til dig.</div>');

				var framed = typeof addon_query === "undefined" ? '' : addon_query;
				var query = "iframe=S87TArSnLA";

				if(framed != '') {
					$('a').each(function () {
						var href = $(this).attr('href');

						if (href) {
							if (!href.match(/\#/)) {
								href += (href.match(/\?/) ? '&' : '?') + query;
								$(this).attr('href', href);
							}
						}
					});
				}

				var container = $('.wishlist-center-list');

				function update_list(centers_list) {
					container.empty();
					$(".wishlist-count").html(centers_list.length);
					if(centers_list.length == 0) {
						$(".form-link").hide();
						$(".deselect-all").hide();
					} else {
						$(".form-link").show();
						$(".deselect-all").show();
					}

					if(centers_list.length == 0) {
						$(".indicator").show();
						$(".order").hide();
					} else {
						$(".indicator").hide();
						$(".order").show();
					}

					$(".choose-center").html('<i class="fa fa-thumbs-up"></i> Føj til tilbud');
					$(".choose-center").removeClass('btn-chosen');

					var centers = '';
					centers_list.each(function(obj, index) {
						var button = $("#center-" + obj.id);
						centers += obj.internal_id + ',';

						var template = Handlebars.compile($("#wishlist-template").html());
						var center = template({
							id: obj.id,
							link: obj.link + framed,
							thumbnail: obj.image,
							center_category: obj.category,
							center_name: obj.title,
							rooms: obj.rooms,
							chairs: obj.chairs,
							tables: obj.tables,
							category: obj.category
						});

						button.addClass('btn-chosen');
						button.html('<i class="fa fa-check"></i> Valgt til tilbud');

						container.append(center);

						$(".btn-delete-" + obj.id).on('click', function(e) {
							e.preventDefault();
							centers_list.remove(obj);

							if(typeof(Storage) !== "undefined") {
								localStorage.setItem("centers_list", JSON.stringify(centers_list));
							}
							update_list(centers_list);
						});
					});


					var href = $(".form-link").attr('href');
					if(href) {
						href += (href.match(/\?/) ? '&' : '?') + 'centers=' + centers;
						$(".form-link").attr('href', href);
					}
				}

				var centers_list = [];
				if(typeof(Storage) !== "undefined") {
					if(localStorage.getItem("centers_list") != null) {
						centers_list = JSON.parse(localStorage.getItem("centers_list"));

						var temp = [];
						for(var i = 0; i < centers_list.length; i++) {
							temp.push(centers_list[i]);
						}

						centers_list = temp;
						update_list(centers_list);
					}
				}

				$(".choose-all").on('click', function(e) {
					e.preventDefault();

					$(".choose-center:not(.btn-chosen)").trigger('click');
				});

				$(".choose-center").on('click', function(e) {
					e.preventDefault();
					var title = $(this).data('title');
					var link = $(this).data('link');
					var image = $(this).data('image');
					var id = $(this).data('id');
					var internal_id = encodeURIComponent($(this).data('internal_id'));
					var rooms = $(this).data('rooms');
					var chairs = $(this).data('chairs');
					var tables = $(this).data('tables');
					var category = $(this).data('category');

					var obj = {
						'title': title,
						'link': link,
						'image': image,
						'id': id,
						'internal_id': internal_id,
						'rooms': rooms,
						'chairs': chairs,
						'tables': tables,
						'category': category
					};

					var found_obj = centers_list.findAll(obj);
					if(found_obj.length > 0) {
						centers_list.remove(obj);

						$(this).removeClass('btn-chosen');
						$(this).html('<i class="fa fa-thumbs-up"></i> Føj til tilbud');
					} else {
						centers_list.push(obj);

						var target = $('.wishlist');
						var btn = $(this);

						var clone = btn.clone()
							.offset({
								top: btn.offset().top,
								left: btn.offset().left
							})
							.css({
								'opacity': '0.5',
								'position': 'absolute',
								'width': '240px',
								'height': '46px',
								'z-index': '900000',
								'background-color': '#fff',
								'color': '#000',
								'line-height': '46px'
							})
							.appendTo($('body'))
							.animate({
								'top': target.offset().top,
								'left': target.offset().left,
								'width': target.width(),
								'height': target.height()
							}, 1000, 'swing');

						clone.animate({
							'opacity': 0
						}, function () {
							$(this).detach()
						});

						$(this).addClass('btn-chosen');
						$(this).html('<i class="fa fa-check"></i> Valgt til tilbud');

						open = true;
						$(".wishlist").toggleClass('open', open);
					}

					if(typeof(Storage) !== "undefined") {
						localStorage.setItem("centers_list", JSON.stringify(centers_list));
					}

					update_list(centers_list);
				});

				$(".deselect-all").on('click', function(e) {
					e.preventDefault();
					centers_list = [];

					if(typeof(Storage) !== "undefined") {
						localStorage.setItem("centers_list", JSON.stringify(centers_list));
					}

					update_list(centers_list);
				});

				$('.swipebox').swipebox();
			}
		},
		// Home page
		'home': {
			init: function() {
				// JavaScript to be fired on the home page
			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// About us page, note the change from about-us to about_us.
		'about_us': {
			init: function() {
				// JavaScript to be fired on the about us page
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
